<template>
    <div class="wrapper" v-if="this.colegiadoForm">
      <div class="topo-header centro">
        <botao-voltar nomeRota="Colegiado" />
        <h1>{{ colegiadoForm.id ? "Editar" : "Novo" }} Colegiado</h1>
      </div>
       <tabs>
         <tab class="tab-dados-basicos" title="Informações Gerais">
          <div class="form centro">
            <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro" />
            <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>
            <form @submit.prevent="enviarForm">
              <div class="caixa-form item">
                <div class="flexbox"> 
                    <div class="item">
                        <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                        <input type="text" placeholder="Digite o nome" v-model="colegiadoForm.nome" />
                    </div>
                    <div class="item">
                        <label><b>{{ possuiAsterisco("Órgão:") }}</b></label>
                        <v-select 
                            class = "vselect"
                            v-model="selectedOrgao"
                             id="selectedOrgao" 
                            :options="paginated"
                            label="nome" 
                            placeholder="Todos"
                            :filterable="false" 
                            @search="onSearch">
                                <li slot="list-footer" class="pagination">
                                    <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                                    <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                                </li>
                        </v-select>
                    </div>
                </div>
                <div class="flexbox">
                    <div class="item">
                        <div class="radio">
                            <label class="radio-label"><b>{{ possuiAsterisco("Tipo:") }}</b></label>
                            <div class="radio-item">
                                <div class="options">
                                    <input type="radio" id="conselho" name="tipoOptions" value="conselho" v-model="colegiadoForm.tipo">
                                    <label for ="conselho">Conselho</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="comite" name="tipoOptions" value="comite" v-model="colegiadoForm.tipo">
                                    <label for ="comite">Comitê</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="forum" name="tipoOptions" value="forum" v-model="colegiadoForm.tipo">
                                    <label for ="forum">Fórum</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="diretoria" name="tipoOptions" value="diretoria" v-model="colegiadoForm.tipo">
                                    <label for ="diretoria">Diretoria</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="camara" name="tipoOptions" value="camara" v-model="colegiadoForm.tipo">
                                    <label for ="camara">Câmara</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="colegio" name="tipoOptions" value="colegio" v-model="colegiadoForm.tipo">
                                    <label for ="colegio">Colégio</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="junta" name="tipoOptions" value="junta" v-model="colegiadoForm.tipo">
                                    <label for ="junta">Junta</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="radio">
                            <label class="radio-label"><b>{{ possuiAsterisco("Status:") }}</b></label>
                            <div class="radio-item">
                                <div class="options">
                                    <input type="radio" id="em-estruturacao" name="statusOptions" value="em_estruturacao" v-model="colegiadoForm.status">
                                    <label for ="em-estruturacao"> Em Estruturação</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="ativo" name="statusOptions" value="ativo" v-model="colegiadoForm.status">
                                    <label for ="ativo"> Ativo</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="inativo" name="statusOptions" value="inativo" v-model="colegiadoForm.status">
                                    <label for ="inativo"> Inativo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="flexbox">
                        <!-- Campos condicionais para status inativo -->
                        <div class="item" v-show="colegiadoForm.status === 'inativo'">
                            <label><b>{{ possuiAsterisco("Motivo do Status Inativo:") }}</b></label>
                            <input type="text" v-model="colegiadoForm.status_motivo" class="text-input" placeholder="Insira o motivo do status inativo">
                        </div>
                        <div class="item" v-show="colegiadoForm.status === 'inativo'">
                            <label><b>{{ possuiAsterisco("Link do Motivo do Status Inativo:") }}</b></label>
                            <input type="text" v-model="colegiadoForm.status_link_motivo" class="text-input" placeholder="Insira o link do motivo do status inativo">
                        </div>
                    </div>
                    <div v-show="colegiadoForm.status !== 'inativo' && colegiadoForm.status !== 'em_estruturacao'">
                    <div class="flexbox" >
                        <div class="item">
                        <div class="radio">
                            <label class="radio-label"><b>{{ possuiAsterisco("Nomeação via decreto do governador:") }}</b></label>
                            <div class="radio-item">
                                <div class="options">
                                    <input type="radio" id="sim-nomeacao-decreto" name="nomeacaoDecretOptions" :value=true v-model="colegiadoForm.nomeacao_governador">
                                    <label for ="sim-nomeacao-decreto">Sim</label>
                                </div>
                                <div class="options">
                                    <input type="radio" id="nao-nomeacao-decreto" name="nomeacaoDecretOptions" :value=false v-model="colegiadoForm.nomeacao_governador">
                                    <label for ="nao-nomeacao-decreto">Não</label>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Cargo de presidente nomeação em comissão:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-cargo-presidente" name="cargoPresidenteOptions" :value=true v-model="colegiadoForm.cargo_presidente">
                                        <label for="sim-cargo-presidente">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-cargo-presidente" name="cargoPresidenteOptions" :value=false v-model="colegiadoForm.cargo_presidente">
                                        <label for="nao-cargo-presidente">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>    
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Lista Tríplice:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-lista" name="listaOptions" :value=true v-model="colegiadoForm.lista_triplice">
                                        <label for="sim-lista">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-lista" name="listaOptions" :value=false v-model="colegiadoForm.lista_triplice">
                                        <label for ="nao-lista">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Indicação do Governador:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-indicacao" name="indicacaoOptions" :value=true v-model="colegiadoForm.indicacao_governador">
                                        <label for="sim-indicacao">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-indicacao" name="indicacaoOptions" :value=false v-model="colegiadoForm.indicacao_governador">
                                        <label for ="nao-indicacao">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flexbox">
                    <div class="item">
                        <label class="custom-label"><b>{{ possuiAsterisco("Quantidade de membros titulares: ") }}</b></label>
                        <div class="flexbox-min">
                            <div class="input-wrapper">
                                <input type="number" min="1" max="40" step="1" placeholder="Mín" v-model="colegiadoForm.quantidade_membros_titulares_minimo" @input="inRange(colegiadoForm.quantidade_membros_titulares_minimo)"/>
                            </div>
                            <div class="input-wrapper">
                                <input type="number" min="1" max="40" step="1" placeholder="Máx" v-model="colegiadoForm.quantidade_membros_titulares_maximo" @input="inRange(colegiadoForm.quantidade_membros_titulares_maximo)"/>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <label class="custom-label"><b>{{"Quantidade de membros suplentes: "}}</b></label>
                        <div class="flexbox-min">
                            <div class="input-wrapper">
                                <input type="number" min="0" max="40" step="1" placeholder="Mín" v-model="colegiadoForm.quantidade_membros_suplentes_minimo" @input="inRange(colegiadoForm.quantidade_membros_suplentes_minimo)"/>
                            </div>
                            <div class="input-wrapper">
                                <input type="number" min="0" max="40" step="1" placeholder="Máx" v-model="colegiadoForm.quantidade_membros_suplentes_maximo" @input="inRange(colegiadoForm.quantidade_membros_suplentes_maximo)"/>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <label><b>{{ "Regimento interno:"}}</b></label>
                            <input type="text" placeholder="Insira informações sobre o regimento interno aqui" v-model="colegiadoForm.regimento_interno" />
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ "Link/Arquivo Regimento:" }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="opcao-link" name="tipoRegimento" value="link" v-model="tipo_regimento">
                                        <label for="opcao-link">Link</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="opcao-upload" name="tipoRegimento" value="upload" v-model="tipo_regimento">
                                        <label for="opcao-upload">Upload PDF</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="opcao-not-link" name="tipoRegimento" value="not-upload" v-model="tipo_regimento">
                                        <label for="opcao-not-link">Não Contém Link ou Pdf</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item" v-if="tipo_regimento === 'link'">
                            <label><b>{{ possuiAsterisco("Link do regimento interno:") }}</b></label>
                            <input type="text" v-model="colegiadoForm.link_regimento_interno" class="text-input" placeholder="Insira o link do regimento interno">
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="upload item" v-if="tipo_regimento === 'upload'">
                            <MultipleUploads v-model="colegiadoFormm.anexos_attributes"></MultipleUploads>
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <label class="custom-label"><b>{{ possuiAsterisco("Duração do mandato (anos): ") }}</b></label>
                            <div class="input-wrapper">
                                <input type="number" min="0" max="10" step="1" placeholder="Anos" v-model="colegiadoForm.duracao_mandato" @input="inRange(colegiadoForm.duracao_mandato)" />
                            </div>
                        </div>
                        <div class="item">
                            <label class="custom-label"><b>{{ "Intervalo do mandato (anos): " }}</b></label>
                            <div class="input-wrapper">
                                <input type="number" min="0" max="10" step="1" placeholder="Anos" v-model="colegiadoForm.intervalo_mandato" @input="inRange(colegiadoForm.intervalo_mandato)" />
                            </div>
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Remunerado:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-remunerado" name="remuneradoOptions" :value=true v-model="colegiadoForm.remunerado">
                                        <label for="sim-remunerado">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-remunerado" name="remuneradoOptions" :value=false v-model="colegiadoForm.remunerado">
                                        <label for="nao-remunerado">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item" v-if="colegiadoForm.remunerado === true || colegiadoForm.remunerado === 'true'">
                            <label><b>{{ possuiAsterisco("Valor remunerado") }}</b></label>
                            <input type="text" placeholder="Digite o valor" v-model="colegiadoForm.valor_remunerado">
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Referendar os nomes pelos chefes de cada poder:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-referendar" name="referendarOptions" :value=true v-model="colegiadoForm.referendar_nome">
                                        <label for="sim-referendar">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-referendar" name="referendarOptions" :value=false v-model="colegiadoForm.referendar_nome">
                                        <label for="nao-referendar">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">   
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Sabatina da ALEGO:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-sabatina" name="sabatinaOptions" :value=true v-model="colegiadoForm.sabatina_alego">
                                        <label for="sim-sabatina">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-sabatina" name="sabatinaOptions" :value=false v-model="colegiadoForm.sabatina_alego">
                                    <label for="nao-sabatina">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ "Presidente do conselho é indicado pelo Governador?" }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-presi-indicado" name="presi-indicadoOptions" :value=true v-model="colegiadoForm.presidente_indicado">
                                        <label for="sim-presi-indicado">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-presi-indicado" name="presi-indicadoOptions" :value=false v-model="colegiadoForm.presidente_indicado">
                                        <label for="nao-presi-indicado">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">   
                            <div class="radio">
                                <label class="radio-label"><b>{{ "Membro eleito em assembleia?" }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-eleito" name="eleitoOptions" :value=true v-model="colegiadoForm.membro_eleito">
                                        <label for="sim-sabatina">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-eleito" name="eleitoOptions" :value=false v-model="colegiadoForm.membro_eleito">
                                    <label for="nao-eleito">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ "Presidente é membro do Conselho de Administração?" }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-presi-conselho" name="presi-conselhoOptions" :value=true v-model="colegiadoForm.presidente_admin">
                                        <label for="sim-presi-conselho">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-presi-conselho" name="presi-conselhoOptions" :value=false v-model="colegiadoForm.presidente_admin">
                                        <label for="nao-presi-conselho">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">   
                            <div class="radio">
                                <label class="radio-label"><b>{{ "Continuidade no mandato?"}}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-continuidade" name="continuidadeOptions" :value=true v-model="colegiadoForm.continua_mandato">
                                        <label for="sim-continuidade">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-continuidade" name="continuidadeOptions" :value=false v-model="colegiadoForm.continua_mandato">
                                    <label for="nao-continuidade">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="flexbox">
                        <div class="item">
                            <div class="radio">
                                <label class="radio-label"><b>{{ possuiAsterisco("Permite Recondução:") }}</b></label>
                                <div class="radio-item">
                                    <div class="options">
                                        <input type="radio" id="sim-permite" name="permiteOptions" :value=true v-model="colegiadoForm.permite_reconducao">
                                        <label for="sim-permite">Sim</label>
                                    </div>
                                    <div class="options">
                                        <input type="radio" id="nao-permite" name="permiteOptions" :value=false v-model="colegiadoForm.permite_reconducao">
                                        <label for="nao-permite">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item" v-if="colegiadoForm.permite_reconducao === true || colegiadoForm.permite_reconducao === 'true'">
                            <label><b>{{ possuiAsterisco("Quantidade de Recondução:") }}</b></label>
                            <input type="number" min="0" max="10" step="1" placeholder="Digite a quantidade de recondução" v-model="colegiadoForm.quantidade_reconducao" @input="inRange(colegiadoForm.permite_reconducao)">
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <label><b>{{ "Legislação:" }}</b></label>
                            <input type="text" placeholder="Insira informações sobre a legislação aqui" v-model="colegiadoForm.legislacao" />
                        </div>
                        <div class="item" v-if = "colegiadoForm.legislacao">
                            <label><b>{{ "Link da Legislação:"}}</b></label>
                            <input type="text" v-model="colegiadoForm.link_legislacao" class="text-input" placeholder="Insira o link da legislação aqui">
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <label><b>Representantes da sociedade civil organizada:</b></label>
                            <input type="text" placeholder="Insira informações sobre representantes da sociedade civil organizada aqui " v-model="colegiadoForm.representantes_sociedade" />
                        </div>
                    </div>
                    <div class="flexbox">
                        <div class="item">
                            <label><b>Observação:</b></label>
                            <textarea v-model="colegiadoForm.observacao" class="textarea" id="body" placeholder="Escreva uma observação aqui" @input="resize"></textarea>
                        </div>
                    </div>
                </div>
              </div>
              <div class="bt-salvar">
                <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                  <img id="spinner-loading" class="spinner-loading" src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                  <p id="conteudo-botao">{{ colegiadoForm.id ? "Atualizar" : "Criar" }}</p>
                </button>
              </div>
            </form>
          </div>
        </tab>
        <tab class="tab-conteudo" title="Mandatos" v-if="colegiadoForm.id">            
            <div class="blocos-textos">
                <div class="form centro">
                    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
                    <div class="caixa-form-blocos item">
                        <Mandatos :colegiadoId="colegiadoForm.id" :key="mandatosKey" @form-saved="handleFormSaved" ></Mandatos>
                    </div>
                </div>
            </div>         
        </tab>
      </tabs>
      <div v-if="modalConfirmacaoAtualizacaoVisivel" class="modal-exclusao">
        <div class="modal-mask">
            <div class="modal-container">
            <div class="modal-header">
                <h2>Deseja realmente atualizar este colegiado?</h2>
            </div>
            <div class="modal-footer">
                <button class="btn-confirmar-exclusao" @click="confirmarAtualizacaoColegiado">Sim</button>
                <button class="btn-cancelar-exclusao" @click="fecharModalConfirmacaoAtualizacao">Não</button>
            </div>
            </div>
        </div>
      </div>

    </div>
</template>


<script>
    import vSelect from 'vue-select';
    import ColegiadoApi from '../../services/ColegiadoApi';
    import BotaoVoltar from '../../components/BotaoVoltar.vue';
    import MsgErroFormularios from '../MsgErroFormularios';
    import Tab from '../Tab.vue';
    import Tabs from '../Tabs.vue';
    import MultipleUploads from '../MultipleUploads.vue';
    import Mandatos from './MandatosForm.vue';

    export default {
        name: 'ColegiadoForm',
        props: {
            colegiado: {
                type: Object,
                required: false,
            },
        },
        watch: {
            'colegiadoForm.remunerado'(newValue) {
                if (newValue) {
                    this.colegiadoForm.valor_remunerado = null;
                }
            },
            'colegiadoForm.legislacao'(newValue) {
                if (!newValue) {
                    this.colegiadoForm.link_legislacao = '';
                }
            },
            'colegiadoForm.status'(newValue) {
                if (newValue) {
                    this.colegiadoForm.status_motivo = null;
                    this.colegiadoForm.status_link_motivo = null;
                }
            },
            'colegiadoForm.permite_reconducao'(newValue) {
                if (newValue) {
                    this.colegiadoForm.quantidade_reconducao = null;
                }
            },
            'tipo_regimento'(newValue) {
                
                if (newValue === 'link') {
                    this.colegiadoFormm.anexos_attributes = [];
                }else if(newValue === 'upload'){
                    this.colegiadoForm.link_regimento_interno = '';
                }else{
                    this.colegiadoForm.link_regimento_interno = '';
                    this.colegiadoFormm.anexos_attributes = [];
                }
            },
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
            vSelect,
            Tab,
            Tabs,
            MultipleUploads,
            Mandatos,
        },
        data() {
            return {
                nomeRotaAtual: this.$router.currentRoute.name,
                carregando: false,
                erro: false,
                count: -1,
                count1: null,
                filess: null,
                enviandoForm: false,
                modalConfirmacaoAtualizacaoVisivel: false, 
                isEditing: false,
                msgErros: {
                    tipo: '',
                    status: '',
                    status_motivo: '',
                    status_link_motivo: '',
                    nomeacao_governador:'',
                    cargo_presidente:'',
                    lista_triplice: '',
                    indicacao_governador: '',
                    nome: '',
                    orgao_id: '',
                    quantidade_membros_titulares_minimo: '',
                    quantidade_membros_titulares_maximo: '',
                    quantidade_membros_suplentes_minimo: '',
                    quantidade_membros_suplentes_maximo: '',
                    regimento_interno: '',
                    link_regimento_interno: '',
                    arquivo_regimento_interno: '',
                    duracao_mandato: '',
                    intervalo_mandato: '',
                    remunerado: '',
                    valor_remunerado: '',
                    referendar_nome:'',
                    sabatina_alego: '',
                    permite_reconducao: '',
                    quantidade_reconducao: '',
                    legislacao:'',
                    link_legislacao:'',
                    representantes_sociedade: '',
                    observacao: '',
                    default: '',
                },
                colegiadoApi: null,
                colegiadoFormm: this.colegiado
                ? {
                    anexos_attributes: this.colegiado.anexos
                                        ? this.colegiado.anexos
                                        : [],
                }
                : {
                    anexos_attributes: [],
                },
                colegiadoForm: this.colegiado
                ? {
                        id: this.colegiado.id,
                        tipo: this.colegiado.tipo,
                        orgao_id: this.colegiado.orgao_id,
                        nome: this.colegiado.nome,
                        quantidade_membros_titulares_minimo: this.colegiado.quantidade_membros_titulares_minimo,
                        quantidade_membros_titulares_maximo: this.colegiado.quantidade_membros_titulares_maximo,
                        quantidade_membros_suplentes_minimo: this.colegiado.quantidade_membros_suplentes_minimo,
                        quantidade_membros_suplentes_maximo: this.colegiado.quantidade_membros_suplentes_maximo,
                        regimento_interno: this.colegiado.regimento_interno,
                        link_regimento_interno: this.colegiado.link_regimento_interno,
                        status: this.colegiado.status,
                        status_motivo: this.colegiado.status_motivo,
                        status_link_motivo: this.colegiado.status_link_motivo,
                        duracao_mandato: this.colegiado.duracao_mandato,
                        intervalo_mandato: this.colegiado.intervalo_mandato,
                        remunerado: this.colegiado.remunerado,
                        valor_remunerado: this.colegiado.valor_remunerado,
                        sabatina_alego: this.colegiado.sabatina_alego,
                        referendar_nome: this.colegiado.referendar_nome,
                        nomeacao_governador: this.colegiado.nomeacao_governador,
                        cargo_presidente: this.colegiado.cargo_presidente,
                        lista_triplice: this.colegiado.lista_triplice,
                        indicacao_governador: this.colegiado.indicacao_governador,
                        permite_reconducao: this.colegiado.permite_reconducao,
                        quantidade_reconducao: this.colegiado.quantidade_reconducao,
                        legislacao: this.colegiado.legislacao,
                        link_legislacao: this.colegiado.link_legislacao,
                        representantes_sociedade: this.colegiado.representantes_sociedade,
                        membro_eleito: this.colegiado.membro_eleito,
                        presidente_indicado: this.colegiado.presidente_indicado,
                        continua_mandato: this.colegiado.continua_mandato,
                        presidente_admin: this.colegiado.presidente_admin,
                        observacao: this.colegiado.observacao
                    }
                : {
                    id: null,
                    tipo: null,
                    anexos_attributes: [],
                    orgao_id: null,
                    nome: null,
                    quantidade_membros_titulares_minimo: null,
                    quantidade_membros_titulares_maximo: null,
                    quantidade_membros_suplentes_minimo: null,
                    quantidade_membros_suplentes_maximo: null,
                    regimento_interno: null,
                    link_regimento_interno: null,
                    status: null,
                    status_motivo: null,
                    status_link_motivo: null,
                    duracao_mandato: null,
                    intervalo_mandato: null,
                    remunerado: null,
                    valor_remunerado: null,
                    referendar_nome: null,
                    sabatina_alego: null,
                    nomeacao_governador: null,
                    cargo_presidente: null,
                    lista_triplice: null,
                    indicacao_governador: null,
                    permite_reconducao: null,
                    quantidade_reconducao: null,
                    legislacao: null,
                    link_legislacao: null,
                    representantes_sociedade: null,
                    membro_eleito:null,
                    presidente_indicado: null,
                    continua_mandato: null,
                    presidente_admin: null,
                    observacao: null,
                    },
                orgaos: [],
                selectedOrgao: null,
                search: '',
                offset: 0,
                limit: 10 ,
                tipo_regimento: '',
                mandatosKey: 0
        };
        },
        created() {
            this.isEditing = !!this.colegiadoForm.id;
            this.selectedOrgao = this.colegiado ? this.colegiado.orgao_id : null;
            this.colegiadoApi = new ColegiadoApi();
            this.carregarOrgaos();
            this.TipoRegi();
        },
        computed: {
            filtered() {
                return this.orgaos.filter((orgao) =>
                    orgao.nome.toLowerCase().includes(this.search.toLowerCase())
                )
            },
            paginated() {
                return this.filtered.slice(this.offset, this.limit + this.offset)
            },
            hasNextPage() {
                const nextOffset = this.offset + this.limit
                return Boolean(
                    this.filtered.slice(nextOffset, this.limit + nextOffset).length
                )
            },
            hasPrevPage() {
                const prevOffset = this.offset - this.limit
                return Boolean(
                    this.filtered.slice(prevOffset, this.limit + prevOffset).length
                )
            },
        },
        methods: {
            handleFormSaved() {
                setTimeout(() => {
                    this.mandatosKey += 1;
                }, 500);
            },
            abrirModalConfirmacaoAtualizacao() {
                this.modalConfirmacaoAtualizacaoVisivel = true;
            },

            fecharModalConfirmacaoAtualizacao() {
                this.modalConfirmacaoAtualizacaoVisivel = false;
            },

            confirmarAtualizacaoColegiado() {
                this.modalConfirmacaoAtualizacaoVisivel = false;
                this.prosseguirEnvioForm(); // Chama a função que realmente envia o formulário
            },

            onFileChange(e) {
                if (e.target.files && e.target.files.length > 0) {
                    this.filess = e.target.files[0];
                } else {
                    this.filess = null;
                }
            },
            async TipoRegi(){
                if(this.nomeRotaAtual === "ColegiadoEdit"){
                    this.tipo_regimento = this.colegiado.tipo_regimento;       
                }
            },
            async carregarOrgaos() {
                try {
                    this.orgaos = await this.colegiadoApi.getOrgao();
                    
                    this.orgaos.map((orgao) => {
                        if (orgao.sigla !== undefined && orgao.sigla !== '') {
                            orgao.nome = orgao.sigla + ' - ' + orgao.nome;
                        } else {
                            orgao.nome;
                        }
                    });
                    if (this.nomeRotaAtual == 'ColegiadoEdit') {
                        this.orgaos.map((orgao) => {
                            if (orgao.id == this.colegiado.orgao_id) {
                                this.selectedOrgao = orgao;
                            }
                        })
                    }
                } catch (error) {
                    console.error('Erro ao carregar os órgãos:', error);
                    this.erroFiltro = true;
                    this.orgaos = [];
                }
            },
            async enviarForm() {
                if (this.isEditing) {
                this.abrirModalConfirmacaoAtualizacao();
                } else {
                    this.prosseguirEnvioForm(); // Se não for edição, prossegue normalmente
                }
            },
            async prosseguirEnvioForm() {
                this.enviandoForm = true;

                let flag = this.validarForm(this.colegiadoForm);
                
                if (flag === 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.enviandoForm = false;
                    return false;
                }

                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.selectedOrgao !== null) {
                        this.colegiadoForm.orgao_id = this.selectedOrgao.id;
                    }

                    if (this.colegiadoApi.cancelTokens.setColegiado) {
                        this.colegiadoApi.cancelTokens.setColegiado.cancel();
                    }

                    let data;
                    data = await this.colegiadoApi.setColegiado(this.colegiadoForm);

                    // Upload de multiplos anexos
                    if (this.colegiadoFormm) {  
                        const aux = this.colegiadoFormm.anexos_attributes.length;
                        var aux1 = 0;
                        
                        for (var i = 0; i < aux; i++) {
                            if (this.colegiadoFormm.anexos_attributes[i].url) {
                                aux1++;
                            }
                        }

                        this.colegiadoApi.setColegiadoFormDataDestoy(data.id, this.colegiadoFormm, aux1);
                        if (aux !== aux1) {
                            this.colegiadoApi.setColegiadoFormData(data.id, this.colegiadoFormm, aux, aux1);
                        }
                    }

                    this.$router.push({
                        name: 'ColegiadoShow',
                        params: {
                            id: data.id,
                        },
                    });
                } catch (err) {
                    console.error(err);
                    this.erro = true;

                    const { response } = err;

                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros = 'Houve um erro ao salvar o registro. Por favor, tente novamente.';
                    }
                } finally {
                    this.enviandoForm = false;
                }
            },
            inRange(valor) {
                let divMensagem = document.getElementById("mensagem");

                if (divMensagem) {
                    if (valor > 99 || valor < 0) {
                        divMensagem.style.display = "block";
                    } else {
                        divMensagem.style.display = "none";
                    }
                }
            },
            validarForm(colegiadoForm) {
                let flag = 0;
                this.msgErros = {
                    tipo: '',
                    status: '',
                    status_motivo: '',
                    status_link_motivo: '',
                    nomeacao_governador:'',
                    cargo_presidente:'',
                    lista_triplice: '',
                    indicacao_governador: '',
                    nome: '',
                    orgao_id: '',
                    quantidade_membros_titulares_minimo: '',
                    quantidade_titulares_minimo_zero: '',
                    quantidade_membros_titulares_maximo: '',
                    quantidade_membros_suplentes_minimo: '',
                    quantidade_membros_suplentes_maximo: '',
                    quantidade_membros_titulares_maior: '',
                    quantidade_membros_suplentes_maior: '',
                    regimento_interno: '',
                    link_regimento_interno: '',
                    arquivo_regimento_interno: '',
                    duracao_mandato: '',
                    intervalo_mandato: '',
                    remunerado: '',
                    valor_remunerado: '',
                    referendar_nome:'',
                    sabatina_alego: '',
                    permite_reconducao: '',
                    quantidade_reconducao: '',
                    legislacao:'',
                    link_legislacao:'',
                    representantes_sociedade: '',
                    observacao: '',
                    default: '',
                };
                
                if (colegiadoForm.tipo == null) {
                    this.msgErros.tipo = 'O campo tipo não foi preenchido.';
                    flag = 1;
                }
                
                if (colegiadoForm.status == null) {
                    this.msgErros.status = 'O campo do status não foi preenchido.';
                    flag = 1;
                }

                if (colegiadoForm.status == 'inativo') {
                    if (colegiadoForm.status_motivo == null) {
                        this.msgErros.status_motivo = 'O campo do motivo do status não foi preenchido.';
                        flag = 1;
                    }
                    
                    if (colegiadoForm.status_link_motivo == null) {
                        this.msgErros.status_link_motivo = 'O campo de link para o motivo do status não foi preenchido.';
                        flag = 1;
                    }
                }
                if(colegiadoForm.status !== 'inativo' && colegiadoForm.status !== 'em_estruturacao'){
                    if (colegiadoForm.nomeacao_governador == null) {
                        this.msgErros.nomeacao_governador = 'O campo nomeação via decreto do governador não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.cargo_presidente == null) {
                        this.msgErros.cargo_presidente = 'O campo cargo de presidente em comissão não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.lista_triplice == null) {
                        this.msgErros.lista_triplice = 'O campo lista triplice não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.indicacao_governador == null) {
                        this.msgErros.indicacao_governador = 'O campo indicacao do governador não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.nome == null) {
                        this.msgErros.nome = 'O campo nome não foi preenchido.';
                        flag = 1;
                    }

                    if (this.selectedOrgao == null) {
                        this.msgErros.orgao_id = 'O campo órgão é obrigatório.';
                        flag = 1;
                    }
                    
                    if (colegiadoForm.quantidade_membros_titulares_minimo == null) {
                        this.msgErros.quantidade_membros_titulares_minimo = 'A quantidade mínima de membros titulares não foi preenchida.';
                        flag = 1;
                    }

                    if (colegiadoForm.quantidade_membros_titulares_maximo == null) {
                        this.msgErros.quantidade_membros_titulares_maximo = 'A quantidade máxima de membros titulares não foi preenchida.';
                        flag = 1;
                    }

                    const quantidade_min_titulares = Number(colegiadoForm.quantidade_membros_titulares_minimo);
                    const quantidade_max_titulares = Number(colegiadoForm.quantidade_membros_titulares_maximo);
                    if (quantidade_min_titulares > quantidade_max_titulares) {
                        this.msgErros.quantidade_membros_titulares_maior = 'A quantidade mínima de membros titulares não pode ser maior que a quantidade máxima de membros titulares.';
                        flag = 1;
                    }

                    if (quantidade_min_titulares == 0) {

                        this.msgErros.quantidade_titulares_minimo_zero = 'A quantidade mínima de membros titulares não pode ser 0.';
                        flag = 1;
                    }
                    /*
                    if (colegiadoForm.quantidade_membros_suplentes_minimo == null) {
                        this.msgErros.quantidade_membros_suplentes_minimo = 'A quantidade mínima de membros suplentes não foi preenchida.';
                        flag = 1;
                    }

                    if (colegiadoForm.quantidade_membros_suplentes_maximo == null) {
                        this.msgErros.quantidade_membros_suplentes_maximo = 'A quantidade máxima de membros suplentes não foi preenchida.';
                        flag = 1;
                    }
                    
                    if (colegiadoForm.quantidade_membros_suplentes_minimo > colegiadoForm.quantidade_membros_suplentes_maximo) {
                        this.msgErros.quantidade_membros_suplentes_maior = 'A quantidade mínima de membros suplentes não pode ser maior que a quantidade máxima de membros suplentes.';
                        flag = 1;
                    }

                    if (colegiadoForm.regimento_interno == null) {
                        this.msgErros.regimento_interno = 'O campo de regimneto interno não foi preenchido.';
                        flag = 1;
                    }*/

                    if (this.tipo_regimento == 'link') {
                        if (colegiadoForm.link_regimento_interno == null) {
                            this.msgErros.link_regimento_interno = 'O campo de link do regimento interno não foi preenchido.';
                            flag = 1;
                        }
                    } 
                    
                    if (this.colegiado == null && colegiadoForm.duracao_mandato == null) {
                        this.msgErros.duracao_mandato = 'O campo  duracao de mandato não foi preenchido.';
                        flag = 1;
                    }

                    /*if (colegiadoForm.intervalo_mandato == null) {
                        this.msgErros.intervalo_mandato = 'O campo intervalo de mandato não foi preenchido.';
                        flag = 1;
                    }*/

                    if (colegiadoForm.remunerado == null) {
                        this.msgErros.remunerado = 'O campo remunerado não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.remunerado == true) {
                        if (colegiadoForm.valor_remunerado == null) {
                            this.msgErros.valor_remunerado = 'O campo valor remunerado não foi preenchido.';
                            flag = 1;
                        }
                    }

                    if (colegiadoForm.referendar_nome == null) {
                        this.msgErros.referendar_nome = 'O campo Referendar os nomes pelos chefes de cada poder não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.sabatina_alego == null) {
                        this.msgErros.sabatina_alego = 'O campo Sabatina da ALEGO não foi preenchido.';
                        flag = 1;
                    }
                    
                    if (colegiadoForm.permite_reconducao == null) {
                        this.msgErros.permite_reconducao = 'O campo permite reconducao não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.permite_reconducao == true) {
                        if (colegiadoForm.quantidade_reconducao == null) {
                            this.msgErros.quantidade_reconducao = 'O campo quantidade reconducaoe não foi preenchido.';
                            flag = 1;
                        }
                    }
                    /*
                    if (colegiadoForm.legislacao == null) {
                        this.msgErros.legislacao = 'O campo legislacao não foi preenchido.';
                        flag = 1;
                    }

                    if (colegiadoForm.link_legislacao == null) {
                        this.msgErros.link_legislacao = 'O campo de link da legislacao não foi preenchido.';
                        flag = 1;
                    } 
                    */
                }
                return flag;
            },
            resize(event) {
                const textarea = event.target;
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 'px';
            },
            onSearch(query) {
                this.search = query;
                this.offset = 0;
            },
            possuiAsterisco(texto) {
                return '* ' + texto;
            },
            fecharErro() {
                this.erro = false;
                this.erroModal = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Karla', sans-serif;
    }

    .item {
        margin: .3rem 0
    }
    .modal-exclusao {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
          border-radius: 1rem;
          position: relative;
          z-index: 1000;
          top: 20vh;
          width: calc(40vw - 10rem);
          height: fit-content;
          background-color: #f2f2f2;
          transition: opacity 0.3s ease;
          margin: 0 auto;
        }

        .modal-container {
          height: 100%;
          padding: .7rem;
          position: relative;
          overflow-y: auto;
          overflow-x: auto;
          font-family: "Montserrat-Medium";

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 0 .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }
        }
        .modal-footer {
          margin: .7rem auto 0rem auto;
          place-content: space-evenly;
          font-size: 1rem;

          .btn-confirmar-exclusao {
            border: none;
            border-radius: .5rem;
            width: 30%;
            height: 2.2rem;
            background-color: #005516;
            color: #f2f2f2;
          }

          .btn-cancelar-exclusao {
            border: none;
            border-radius: .5rem;
            width: 30%;
            height: 2.2rem;
            background-color: #bd0000;
            color: #f2f2f2;
          }
        }
      }
    @media screen and (min-width: "1066px") {
        .tab-conteudo {
            .centro {
                max-width: 100%;
            }
        }

        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #fff;
            margin: 0;
            padding: 20px;

            .centro {
                max-width: 85rem;
            }
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }

        .topo-header {
          display: flex;
          align-items: center;

            img {
                height: 1.5rem;
            }
        }
        
        .v-select {
            font-size: .9rem;
            li {
                color: #000;
                padding: .5rem;
                background-color: white;
            }

            li:hover {
                background-color: #dbdbdb;
                font-weight: bold;
            }

            input.vs__search {
                border: none;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            box-shadow: #005516 0px 5px 15px;

            .upload {
                width: fit-content;
                #anexos {
                    display: grid;
                    gap: .5rem;
                    width: auto;

                    .itemTop {
                        display: flex;
                        gap: .7rem;
                        align-items: center;

                        label {
                            width: fit-content;
                            height: fit-content;
                        }

                        button {
                            margin: 0;
                        }
                    }

                    .caixa-form {
                        border-radius: 1rem;
                        padding: 0rem;
                    }
                }
            }

                .flexbox {
                    display: flex;
                    margin: 1rem 0 0 0;
                    gap: 2rem;

                    .item {
                        margin-left: 0;
                        display: grid;
                        width: -webkit-fill-available;

                        .radio {
                            display: grid;
                            cursor: default;

                            .radio-item {
                                display: flex;
                                gap: .7rem;
                                cursor: default;
                                align-self: center;

                                input {
                                    margin: 0;
                                    cursor: pointer;
                                }
                                label {
                                    margin: 0;
                                }
                                .options {
                                    display: flex;
                                    gap: .3rem;
                                }
                            }
                        }
                        .flexbox-min {
                            display: flex;
                            width: fit-content;
                            gap: 1rem;
                        }
                        label {
                            display: inline;
                            margin-bottom: 0.3rem;
                            color: black;
                            font-family: "Montserrat-Medium";
                            font-size: 0.9rem;
                            width: fit-content;
                        }
                    }
                }

                .flexbox-custom-1 {
                    display: grid;
                    grid-template-columns: 1fr .5fr .5fr;
                    gap: 1rem;
                    margin: 1rem 0;

                    .item {
                        margin-left: 0;
                        display: grid;
                        width: -webkit-fill-available;

                        .radio {
                            display: grid;
                            cursor: default;

                            .radio-item {
                                display: flex;
                                gap: .7rem;
                                cursor: default;
                                align-self: center;

                                input {
                                    margin: 0;
                                    cursor: pointer;
                                }
                                label {
                                    margin: 0;
                                }
                                .options {
                                    display: flex;
                                    gap: .3rem;
                                }
                            }
                        }
                        .flexbox-min {
                            display: flex;
                            width: fit-content;
                            gap: 1rem;
                        }
                        label {
                            display: inline;
                            margin-bottom: 0.3rem;
                            color: black;
                            font-family: "Montserrat-Medium";
                            font-size: 0.9rem;
                            width: fit-content;
                        }
                    }
                }

                .flexbox-custom-2 {
                    display: grid;
                    grid-template-columns: .1fr .9fr;
                    gap: 1rem;
                    margin: 1rem 0;

                .item {
                    margin-left: 0;
                    .radio {
                        display: grid;

                            .radio-item {
                                gap: .4rem;
                                cursor: default;

                                input {
                                    margin: 0;
                                }
                                label {
                                    margin: 0 .5rem 0 0;
                                }
                            }
                        }
                    }    
                }

                .flexbox-custom-3 {
                    display: grid;
                    grid-template-columns: .2fr .8fr;
                    gap: 1rem;
                    margin: 1rem 0;

                .item {
                    margin-left: 0;
                    .radio {
                        display: grid;

                            .radio-item {
                                gap: .4rem;
                                cursor: default;
                                input {
                                    margin: 0;
                                }
                                label {
                                    margin: 0 .5rem 0 0;
                                }
                            }
                        }
                    }    
                }

            .btn-carregar-dados {
                align-self: center;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                color: #fff;
                background-color: #139b2b;
                border: none;
                font-size: .8rem;
                border-radius: 2rem;
                padding: .2rem .5rem;
                margin: 1.5rem 0 0 0;

                p {
                    text-align: center;
                    margin: .2rem .2rem;
                }
            }

            .btn-carregar-dados:hover {
                background-color: #139b2cbc;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 3fr 1.5fr;
                gap: 1.5rem;
            }

            input,
            the-mask {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            input[type=number] {
                width: auto;
                padding: 0.3rem;
            }

            input[type=text] {
                padding-left: .5rem;
            }

            input[type="file"] {
                display: none;
            }

            input:disabled {
                background: white;
            }

            textarea#body {
                padding: .5rem 0 0 .5rem;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }
        }

        .bt-salvar {
            text-align: center;
            margin: 1.5rem 0 0 0;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
                p {
                    margin: 0;
                }
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }
  }

  @media screen and (min-width: "60px") and (max-width: "1065px") {
      .wrapper {
          width: 100%;
          min-height: 100vh;
          background-color: #f8f8f8;
          margin: 0;
          padding: 20px;
      }

      h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
      }

      .topo-header {
          display: flex;
          align-items: center;

          img {
              height: 1.5rem;
          }
      }

      .caixa-form {
          background-color: #dbdbdb;
          padding: 1rem;
          border-radius: 1rem;
          border: 2px solid #00a339;

          .btn-carregar-dados {
              align-self: center;
              width: fit-content;
              height: fit-content;
              cursor: pointer;
              color: #fff;
              background-color: #139b2b;
              border: none;
              font-size: .8rem;
              border-radius: 2rem;
              padding: .2rem .8rem;
          }

          .btn-carregar-dados:hover {
              background-color: #139b2cbc;
          }

          input {
              border-radius: 0.5rem;
              border: solid 0.1rem;
          }

          /* input[type="file"]{
                      border-radius: 0.5rem;
                      border: none;
                      width: 44.27rem;
                  } */
          input[type="file"] {
              display: none;
          }

          .custom-file-upload {
              border-radius: 0.5rem;
              border: solid 0.1rem;
              display: inline-block;
              padding: 6px 12px;
              margin-left: 0.4rem;
              margin-right: 0.4rem;
              cursor: pointer;
          }

          input:disabled {
              background: white;
          }

          h2 {
              margin-top: 0;
              text-transform: uppercase;
              color: #005516;
              font-size: 1.2rem;
              font-family: "Montserrat-Bold";
              cursor: pointer;
          }

          .item {
              display: grid;
              margin: 1rem 0;

              label {
                  display: inline;
                  margin-bottom: 0.3rem;
                  color: black;
                  font-family: "Montserrat-Medium";
                  font-size: 0.9rem;
              }

              input {
                  height: 2rem;
                  font-family: "Montserrat-Medium";
                  padding-left: 0.5rem;
                  font-size: 0.9rem;
              }

              a:link {
                  color: #ffa600;
              }

              a:link:hover {
                  color: #e49503;
              }

              select {
                  height: 2rem;
                  width: 100%;
                  border-radius: 0.5rem;
                  border: solid 0.1rem;
                  background-color: white;
                  font-family: "Montserrat-Medium";
                  font-size: 0.95rem;
              }

              .upload {
                  background-color: #ffa600;
                  color: white;
                  border: none;
                  font-size: 0.9rem;
                  padding: 2px 6px;
                  border-radius: 0.2rem;
                  margin-left: 0.4rem;
                  margin-right: 0.4rem;
              }

              /* .upload{
                      color: #ffa600;
                      border-radius: 0.5rem;
                      border: #ffa600 solid 0.124rem;
                      display: inline-block;
                      padding: 2px 6px;
                      font-size: 0.9rem;
                      transition: 0.2s;
                      margin-left: 0.4rem;
                      margin-right: 0.4rem;
                      font-family: "Montserrat-Medium";
                      }
                      .upload:hover{
                      color: white;
                      background-color: #ffa600;
                      } */
              .textarea {
                  width: 100%;
                  border-radius: 10px;
                  box-shadow: 0 0 0 1px #000;
                  border: 5px solid transparent;
                  outline: none;
                  padding: 4px;
                  min-height: 150px;
                  box-sizing: border-box;
                  font-family: "Montserrat-Medium";
                  font-size: 0.93rem;
                  color: black;
              }

              .textarea:focus {
                  outline: 1px solid !important;
                  box-shadow: 0 0 0 2px black;
              }

              .mx-datepicker {
                  width: 100%;
              }

              .checkbox {
                  input {
                      width: auto;
                  }

                  .check {
                      --background: #fff;
                      --border: #d1d6ee;
                      --border-hover: #bbc1e1;
                      --border-active: #1b5138;
                      --tick: #fff;
                      position: relative;
                      cursor: pointer;

                      input,
                      svg {
                          width: 21px;
                          height: 21px;
                          display: inline-block;
                      }

                      input {
                          appearance: none;
                          -moz-appearance: none;
                          position: relative;
                          outline: none;
                          background: var(--background);
                          border: none;
                          margin: 0;
                          transform: translateY(5px);
                          padding: 0;
                          cursor: pointer;
                          border-radius: 4px;
                          transition: box-shadow 0.3s;
                          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                          &:hover {
                              --s: 2px;
                              --b: var(--border-hover);
                          }

                          &:checked {
                              --b: var(--border-active);
                          }
                      }

                      svg {
                          pointer-events: none;
                          fill: none;
                          stroke-width: 2px;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke: var(--stroke, var(--border-active));
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 21px;
                          height: 21px;
                          transform: scale(var(--scale, 1)) translateZ(1);
                          margin-top: 0rem;
                      }

                      span {
                          transform: translateY(-5px);
                          display: inline;
                          margin-left: 0.5rem;
                      }

                      &.path {
                          input {
                              &:checked {
                                  --s: 2px;
                                  transition-delay: 0.4s;

                                  &+svg {
                                      --a: 16.1 86.12;
                                      --o: 102.22;
                                  }
                              }
                          }

                          svg {
                              stroke-dasharray: var(--a, 86.12);
                              stroke-dashoffset: var(--o, 86.12);
                              transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                          }
                      }
                  }
              }
          }

          .multiselect {
              width: calc(100vw - 5.5rem);
          }
      }

      .caixa-form-blocos {
          background-color: #efefef;
          padding: 1rem;
          border-radius: 0.2rem;

          input {
              border-radius: 0.5rem;
              border: solid 0.1rem;
          }

          /* input[type="file"]{
                      border-radius: 0.5rem;
                      border: none;
                      width: 44.27rem;
                  } */
          input[type="file"] {
              display: none;
          }

          .custom-file-upload {
              border-radius: 0.5rem;
              border: solid 0.1rem;
              display: inline-block;
              padding: 6px 12px;
              margin-left: 0.4rem;
              margin-right: 0.4rem;
              cursor: pointer;
          }

          input:disabled {
              background: white;
          }

          h2 {
              margin-top: 0;
              text-transform: uppercase;
              color: #005516;
              font-size: 1.2rem;
              font-family: "Montserrat-Bold";
              cursor: pointer;
          }

          .item {
              display: block;
              margin: 1rem 0;

              label {
                  display: inline;
                  margin-bottom: 0.3rem;
                  color: black;
                  font-family: "Montserrat-Medium";
                  font-size: 0.9rem;
              }

              input {
                  height: 2rem;
                  font-family: "Montserrat-Medium";
                  padding-left: 0.5rem;
                  font-size: 0.9rem;
              }

              a:link {
                  color: #ffa600;
              }

              a:link:hover {
                  color: #e49503;
              }

              select {
                  height: 2rem;
                  width: 100%;
                  border-radius: 0.5rem;
                  border: solid 0.1rem;
                  background-color: white;
                  font-family: "Montserrat-Medium";
                  font-size: 0.95rem;
              }

              .upload {
                  background-color: #ffa600;
                  color: white;
                  border: none;
                  font-size: 0.9rem;
                  padding: 2px 6px;
                  border-radius: 0.2rem;
                  margin-left: 0.4rem;
                  margin-right: 0.4rem;
              }

              /* .upload{
                      color: #ffa600;
                      border-radius: 0.5rem;
                      border: #ffa600 solid 0.124rem;
                      display: inline-block;
                      padding: 2px 6px;
                      font-size: 0.9rem;
                      transition: 0.2s;
                      margin-left: 0.4rem;
                      margin-right: 0.4rem;
                      font-family: "Montserrat-Medium";
                      }
                      .upload:hover{
                      color: white;
                      background-color: #ffa600;
                      } */
              .textarea {
                  width: 100%;
                  border-radius: 10px;
                  box-shadow: 0 0 0 1px #000;
                  border: 5px solid transparent;
                  outline: none;
                  padding: 4px;
                  min-height: 150px;
                  box-sizing: border-box;
                  font-family: "Montserrat-Medium";
                  font-size: 0.93rem;
                  color: black;
              }

              .textarea:focus {
                  outline: 1px solid !important;
                  box-shadow: 0 0 0 2px black;
              }

              .mx-datepicker {
                  width: 100%;
              }

              .checkbox {
                  input {
                      width: auto;
                  }

                  .check {
                      --background: #fff;
                      --border: #d1d6ee;
                      --border-hover: #bbc1e1;
                      --border-active: #1b5138;
                      --tick: #fff;
                      position: relative;
                      cursor: pointer;

                      input,
                      svg {
                          width: 21px;
                          height: 21px;
                          display: inline-block;
                      }

                      input {
                          appearance: none;
                          -moz-appearance: none;
                          position: relative;
                          outline: none;
                          background: var(--background);
                          border: none;
                          margin: 0;
                          transform: translateY(5px);
                          padding: 0;
                          cursor: pointer;
                          border-radius: 4px;
                          transition: box-shadow 0.3s;
                          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                          &:hover {
                              --s: 2px;
                              --b: var(--border-hover);
                          }

                          &:checked {
                              --b: var(--border-active);
                          }
                      }

                      svg {
                          pointer-events: none;
                          fill: none;
                          stroke-width: 2px;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke: var(--stroke, var(--border-active));
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 21px;
                          height: 21px;
                          transform: scale(var(--scale, 1)) translateZ(1);
                          margin-top: 0rem;
                      }

                      span {
                          transform: translateY(-5px);
                          display: inline;
                          margin-left: 0.5rem;
                      }

                      &.path {
                          input {
                              &:checked {
                                  --s: 2px;
                                  transition-delay: 0.4s;

                                  &+svg {
                                      --a: 16.1 86.12;
                                      --o: 102.22;
                                  }
                              }
                          }

                          svg {
                              stroke-dasharray: var(--a, 86.12);
                              stroke-dashoffset: var(--o, 86.12);
                              transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                          }
                      }
                  }
              }
          }

          .multiselect {
              width: 100%;
          }
      }

      .blocos-textos {
          margin: 1rem 0rem;
      }

      .bt-salvar {
          text-align: center;
          margin: 1.5rem 0 0 0;

          button {
              background-color: #005516;
              color: white;
              border: none;
              font-size: 1rem;
              padding: 0.4rem 2rem;
              border-radius: 2rem;
              margin: 0.2rem;
              p {
                  margin: 0;
              }
          }

          label {
              display: none;
              padding-left: 10px;
              width: 10px;
          }
      }

      .bt-salvar-tab {
          text-align: center;
          margin: 1rem;

          button {
              background-color: #005516;
              color: white;
              border: none;
              font-size: 1rem;
              padding: 0.4rem 2rem;
              border-radius: 2rem;
              margin: 0.2rem;
          }

          label {
              display: none;
              padding-left: 10px;
              width: 10px;
          }
      }

      .bt-excluir {
          img {
              cursor: pointer;
              width: 23px;
              padding: 0.2rem;
              height: 1.4rem;
              display: inline-block;
          }

          button {
              border-radius: 1rem;
              border: none;
          }
      }

      .spinner-loading {
          visibility: hidden;
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          margin: 0rem .5rem 0rem -0.5rem;
      }

      #botao-salvar:hover {
          background-color: #4CAF50;
      }

      #botao-salvar-tab:hover {
          background-color: #4CAF50;
      }
  }
</style>
