import Cookies from 'js-cookie';
import AuthApi from "./AuthApi";

const get = require("lodash.get");

const USUARIO_KEY = "usuario_colegiados";
const AUTH_PUBLIC_COOKIE = 'colegiados_auth_public';
//const AUTH_PUBLIC_COOKIE = 'auth_public';

class Auth {
    constructor() {
        this.authApi = new AuthApi();
    }

    async login(dados) {
        try {
            const { usuario } = await this.authApi.autenticar(dados);
            localStorage.setItem(USUARIO_KEY, JSON.stringify(usuario));
        } catch (err) {
            console.log(err);
            const mensagem = get(err, 'response.data.mensagem', 'Houve um erro ao fazer login. Por favor tente novamente')
            throw mensagem;
        }
    }

    logout() {
        try {
            localStorage.removeItem(USUARIO_KEY);
            Cookies.remove(AUTH_PUBLIC_COOKIE)
            window.close();
        } catch (err) {
            console.log(err);
            const mensagem = get(err, 'response.data.mensagem', 'Houve um erro ao fazer logout. Por favor tente novamente')
            throw mensagem;
        }
    }

    loggedIn() {
        return !!this.usuario && Cookies.get(AUTH_PUBLIC_COOKIE);
    }

    get usuario() {
        const usuarioString = localStorage.getItem(USUARIO_KEY);
        if (usuarioString) {
            return JSON.parse(usuarioString);
        }
        return null;
    }
}

export default Auth;